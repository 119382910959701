import React, { ReactNode, useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useAppDispatch } from 'hooks';
import { Alert } from 'interfaces';
import { removeAlert } from 'reducers/alert.reducer';

type Props = {
  alerts: Alert[];
  children: ReactNode;
};

export const AlertsOverlay: React.FC<Props> = ({ alerts, children }: Props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState<Alert | null>(null);

  const handleClose = (): void => {
    setOpen(false);

    if (alert) {
      dispatch(removeAlert(alert.id));
    }
  };

  useEffect(() => {
    if (alerts.length > 0) {
      setAlert(alerts[0]);
    } else {
      setAlert(null);
    }
  }, [alerts]);

  useEffect(() => {
    setOpen(!!alert);
  }, [alert]);

  return (
    <>
      {alert && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert onClose={handleClose} severity={alert.type} elevation={6} variant="filled">
            {alert.message}
          </MuiAlert>
        </Snackbar>
      )}
      {children}
    </>
  );
};
