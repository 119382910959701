import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useAppDispatch, useQuery } from 'hooks';
import logo from 'images/logo.png';
import { AuthService } from 'services';
import { Loader, Page } from '../components';
import { getMe, setToken } from '../reducers/auth.reducer';

type Props = RouteComponentProps;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    textAlign: 'center',
    padding: theme.spacing(6),
    marginTop: 'auto',
  },
  icon: {
    fontSize: 50,
    marginBottom: theme.spacing(2),
  },
  knowingCta: {
    marginTop: theme.spacing(4),
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
  },
}));

const VerifyEmailPage: React.FC<Props> = ({ history }: Props) => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const verifyEmail = async (verificationToken: string) => {
    try {
      const { location, token, refreshToken } = await AuthService.verifyEmail({
        token: verificationToken,
      });

      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);

      dispatch(setToken(token));

      if (location) {
        setRedirecting(true);

        await dispatch(getMe());

        window.location.assign(location);
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : `${err}`;

      setError(message || 'La vérification d&apos;email a échoué');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = query.get('token');

    if (!token) {
      history.replace('/login');

      return;
    }

    verifyEmail(token);
  }, []);

  const renderContent = () => {
    if (loading) {
      return (
        <>
          <Loader />
          <Typography variant="h6">Vérification en cours...</Typography>
        </>
      );
    }

    if (error) {
      return (
        <>
          <ErrorOutlineIcon color="error" className={classes.icon} />
          <Typography>{error}</Typography>
        </>
      );
    }

    return (
      <>
        <CheckCircleOutlineIcon color="primary" className={classes.icon} />
        <Typography variant="h5">Adresse e-mail vérifiée</Typography>

        {redirecting ? (
          <Box display="flex" flexDirection="row" alignItems={'center'} mt={2}>
            <Box display="flex" mr={2}>
              <Loader size={16} />
            </Box>
            <Typography color="textSecondary">Redirection vers Stripe en cours...</Typography>
          </Box>
        ) : (
          <Typography color="textSecondary">
            Vous pouvez maintenant vous connecter à l&apos;application Gnoing
          </Typography>
        )}
      </>
    );
  };

  return (
    <Page maxWidth="sm" showHeader={false} className={classes.root}>
      <Paper className={classes.paper} elevation={2}>
        <Box flex={1} display="flex" flexDirection="column" alignItems="center">
          {renderContent()}
        </Box>
      </Paper>

      <Box className={classes.logo}>
        <img src={logo} alt="Logo Gnoing" height={40} />
      </Box>
    </Page>
  );
};

export default withRouter(VerifyEmailPage);
