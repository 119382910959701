import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { User } from '../interfaces';
import { AuthService, UserService } from '../services';

type AuthState = {
  fetching: boolean;
  token: string | null;
  currentUser: User | null;
  error?: string | null;
};

const initialState: AuthState = {
  fetching: false,
  token: localStorage.getItem('token') || null,
  currentUser: null,
  error: null,
};

export const login = createAsyncThunk<Partial<AuthState>, { email: string; password: string }>(
  'auth/login',
  async ({ email, password }) => {
    const { token, refreshToken, user } = await AuthService.login({ email, password });

    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);

    return { currentUser: user, token };
  },
);

export const refresh = createAsyncThunk<Partial<AuthState>>('auth/refresh', async () => {
  const refreshToken = localStorage.getItem('refreshToken');

  if (refreshToken) {
    const data = await AuthService.refresh(refreshToken);

    localStorage.setItem('token', data.token);
    localStorage.setItem('refreshToken', data.refreshToken);

    return { token: data.token };
  }

  return { token: null };
});

export const logout = createAsyncThunk<Partial<AuthState>>('auth/logout', async () => {
  const refreshToken = localStorage.getItem('refreshToken');

  if (refreshToken) {
    await AuthService.logout(refreshToken);

    localStorage.removeItem('refreshToken');
  }

  localStorage.removeItem('token');

  return { currentUser: null, token: null };
});

export const getMe = createAsyncThunk<User>('auth/getMe', async () => {
  const user = await UserService.getMe();

  return user;
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;

      localStorage.setItem('token', action.payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(login.pending, state => ({
      ...state,
      error: null,
      fetching: true,
    }));
    builder.addCase(login.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      error: null,
      fetching: false,
    }));
    builder.addCase(login.rejected, (state, action) => ({
      ...state,
      error: action.error.message,
      fetching: false,
      token: null,
    }));
    builder.addCase(refresh.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(logout.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(getMe.pending, state => ({
      ...state,
      fetching: true,
    }));
    builder.addCase(getMe.fulfilled, (state, action) => ({
      ...state,
      fetching: false,
      currentUser: action.payload,
    }));
    builder.addCase(getMe.rejected, state => ({
      ...state,
      fetching: false,
    }));
  },
});

const persistConfig: PersistConfig<AuthState> = {
  key: 'auth',
  storage,
  blacklist: ['fetching', 'error'],
};

export const { setToken } = authSlice.actions;
export default persistReducer(persistConfig, authSlice.reducer);
