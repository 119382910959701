import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { StripeService } from '../../services';
import { Button } from '../button';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const UnregisteredStripeAccountModal: React.FC<Props> = ({ open, onClose }: Props) => {
  const handleClick = async () => {
    const { location } = await StripeService.authorize();

    window.location.assign(location);
  };

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Création d&apos;un compte Stripe</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vous n&apos;avez pas encore créé votre compte Stripe. Cliquez sur le bouton ci-dessous
          pour accéder au formulaire de création du compte.
        </DialogContentText>

        <Box display="flex" justifyContent="center" pt={1}>
          <Button title="Accéder au formulaire" onClick={handleClick} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button title="Fermer" variant="text" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};
