import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks';
import { getIsAuthenticated } from '../selectors';

type Props = RouteProps;

const ProtectedRoute: React.FC<Props> = ({ ...props }: Props) => {
  const location = useLocation();
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
