import axios, { AxiosError } from 'axios';
import { Store } from 'redux';
import { API_URL } from '../config';
import { AlertType } from '../interfaces';
import { addAlert } from '../reducers/alert.reducer';
import { logout, refresh } from '../reducers/auth.reducer';
import { AppDispatch, RootState } from '../store';
import { isAxiosError, isNetworkError } from './axios.utils';

export const api = axios.create({
  baseURL: API_URL,
});

export const setupAxiosInterceptors = (store: Store<RootState>): void => {
  api.interceptors.request.use(config => {
    const token = localStorage.getItem('token');

    const headers = {
      ...config.headers,
      Authorization: token ? `Bearer ${token}` : undefined,
    };

    return { ...config, headers };
  });

  api.interceptors.response.use(
    response => {
      if (response?.data?.message) {
        store.dispatch(
          addAlert({
            message: response.data.message,
            type: AlertType.success,
          }),
        );
      }

      return response;
    },
    async (error: AxiosError | any) => {
      if (isAxiosError(error)) {
        const originalRequest = error.config;

        const status = error.response?.status;
        const message = error.response?.data.message;

        if (
          status === 401 &&
          error.config.url !== '/auth/login' &&
          error.config.url !== '/auth/refresh' &&
          !(originalRequest as any)._retry
        ) {
          (originalRequest as any)._retry = true;

          const refreshToken = localStorage.getItem('refreshToken');

          if (refreshToken) {
            await (store.dispatch as AppDispatch)(refresh());

            return api(originalRequest);
          }
        }

        if (status === 401) {
          (store.dispatch as AppDispatch)(logout());

          return Promise.reject(new Error(message || 'Veuillez vous authentifier à nouveau'));
        }

        if (message) {
          return Promise.reject(new Error(message));
        }

        if (isNetworkError(error)) {
          return Promise.reject(new Error('Impossible de se connecter au serveur'));
        }
      }

      return Promise.reject(error);
    },
  );
};
