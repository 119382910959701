import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { Alert } from 'interfaces';

type AlertState = Array<Alert>;

const initialState: AlertState = [];

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<Omit<Alert, 'id'>>) => {
      return [...state, { id: uuidv4(), ...action.payload }];
    },
    removeAlert: (state, action: PayloadAction<string>) => {
      return [...state.filter(alert => alert.id !== action.payload)];
    },
  },
});

export const { addAlert, removeAlert } = alertSlice.actions;
export default alertSlice.reducer;
