export type Alert = {
  id: string;
  message: string;
  type: AlertType;
};

export enum AlertType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}
