import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import { useAppSelector } from 'hooks';
import { AlertsOverlay } from '../components';
import { getAlertState } from '../selectors';
import HomePage from './home';
import LoginPage from './login';
import LogoutPage from './logout';
import NotFoundPage from './not-found';
import ProtectedRoute from './protected-route';
import ResetPasswordPage from './reset-password';
import VerifyEmailPage from './verify-email';

const App: React.FC = () => {
  const alerts = useAppSelector(getAlertState);

  return (
    <>
      <CssBaseline />
      <AlertsOverlay alerts={alerts}>
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <Route path="/logout" exact component={LogoutPage} />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <Route path="/verify-email" component={VerifyEmailPage} />
          <ProtectedRoute path="/" exact component={HomePage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </AlertsOverlay>
    </>
  );
};

export default App;
