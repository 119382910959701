import React, { ComponentProps, ReactNode } from 'react';
import { CircularProgress, Button as MUIButton } from '@material-ui/core';

type MUIButtonProps = ComponentProps<typeof MUIButton>;

type Props = MUIButtonProps & {
  title: string;
  fetching?: boolean;
};

const defaultProps = {
  fetching: false,
};

export const Button: React.FC<Props> = ({ title, fetching, ...buttonProps }: Props) => {
  const getContent = (): ReactNode => {
    if (fetching) {
      return <CircularProgress color="inherit" size={24} />;
    }

    return title;
  };

  return (
    <MUIButton variant="contained" color="primary" {...buttonProps}>
      {getContent()}
    </MUIButton>
  );
};

Button.defaultProps = defaultProps;
