import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { logout } from 'reducers/auth.reducer';

const LogoutPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout());

    history.replace({ pathname: '/' });
  }, []);

  return null;
};

export default LogoutPage;
