import React, { useEffect } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import logo from 'images/logo.png';
import { login } from 'reducers/auth.reducer';
import { LoginForm, Page } from '../components';
import { getIsAuthenticated } from '../selectors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6),
  },
  logo: {
    height: 100,
  },
}));

const LoginPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation<any>();
  const auth = useAppSelector(state => state.auth);
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  const { from } = location.state || { from: { pathname: '/' } };

  const handleSubmit = ({ email, password }) => {
    dispatch(login({ email, password }));
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.replace(from);
    }
  }, [isAuthenticated]);

  return (
    <Page maxWidth="sm" showHeader={false} className={classes.root}>
      <Paper elevation={2} className={classes.paper}>
        <img src={logo} alt="Logo Gnoing" className={classes.logo} />

        <LoginForm onSubmit={handleSubmit} submitting={auth.fetching} submitError={auth.error} />
      </Paper>
    </Page>
  );
};

export default LoginPage;
