import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const getAuthState = (state: RootState) => state.auth;

export const getCurrentUser = createSelector(getAuthState, auth => auth.currentUser);

export const getIsAuthenticated = createSelector(getAuthState, auth => !!auth.token);

export const getIsAuthenticating = createSelector(getAuthState, auth => !!auth.fetching);
