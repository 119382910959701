import React from 'react';
import { Card, CardContent, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { User } from 'interfaces';

type Props = {
  user: User;
};

export const UserInfo: React.FC<Props> = ({ user }: Props) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Mes informations
        </Typography>

        <List dense>
          <ListItem>
            <ListItemText primary="Prénom" secondary={user.firstName} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Nom" secondary={user.lastName} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Email" secondary={user.email} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Téléphone" secondary={user.phone} />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};
