import { User } from '../interfaces';
import { api } from '../utils/api.utils';

const getMe = async (): Promise<User> => {
  const { data: user } = await api.get<User>('/me');

  return user;
};

export default { getMe };
