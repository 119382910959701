import React from 'react';
import { Card, CardContent, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { UserAddress } from 'interfaces';

type Props = {
  address: UserAddress;
};

export const UserAddressInfo: React.FC<Props> = ({ address }: Props) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Mon adresse
        </Typography>

        <List dense>
          <ListItem>
            <ListItemText primary="Adresse postale" secondary={address.streetAddress} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Code postal" secondary={address.zip} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Ville" secondary={address.city} />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};
