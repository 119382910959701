import { combineReducers } from 'redux';
import alertReducer from './alert.reducer';
import authReducer from './auth.reducer';
import modalReducer from './modal.reducer';

const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  modal: modalReducer,
});

export default rootReducer;
