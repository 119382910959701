import React, { useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'hooks';
import logoSmall from 'images/logo-small.png';
import { getCurrentUser } from '../selectors';

const useStyles = makeStyles(theme => ({
  logo: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    padding: theme.spacing(),
    marginRight: theme.spacing(),
  },
}));

export const Header: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const currentUser = useAppSelector(getCurrentUser);
  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(null);

  const navigateTo = screen => {
    history.push(screen);
  };

  return (
    <>
      <AppBar position="fixed">
        <Container maxWidth="md">
          <Toolbar disableGutters>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Avatar src={logoSmall} alt="Logo Gnoing" className={classes.logo} />
              <Typography variant="h6" color="inherit">
                Tableau de bord
              </Typography>
            </Box>

            {currentUser && (
              <>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
                  color="inherit"
                >
                  <Avatar src={currentUser.picture || undefined} alt={currentUser.firstName} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  open={!!anchorEl}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={() => navigateTo('/logout')}>Déconnexion</MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </>
  );
};
