import React, { useState } from 'react';
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  LockOutlined as LockOutlinedIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { useAppDispatch, useQuery } from 'hooks';
import logo from 'images/logo.png';
import { AlertType } from 'interfaces';
import { addAlert } from 'reducers/alert.reducer';
import { AuthService } from 'services';
import { Button, Page } from '../components';

type Props = RouteComponentProps;

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Le mot de passe est requis'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas')
    .required('La confirmation de mot de passe est requise'),
});

type FormData = Yup.InferType<typeof ResetPasswordSchema>;

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: spacing(10),
    paddingBottom: spacing(10),
  },
  icon: {
    fontSize: 40,
  },
  form: {
    paddingTop: spacing(2),
  },
  submitButton: {
    marginTop: spacing(3),
    marginBottom: spacing(2),
  },
  logo: {
    flex: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ResetPasswordPage: React.FC<Props> = ({ history }: Props) => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const {
    errors,
    formState,
    register,
    handleSubmit,
    reset: resetForm,
  } = useForm<FormData>({
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ResetPasswordSchema,
    reValidateMode: 'onSubmit',
  });

  const token = query.get('token');

  if (!token) {
    history.replace('/login');

    return null;
  }

  const onSubmit = ({ password, passwordConfirm }: FormData) => {
    return AuthService.resetPassword({ token, password, passwordConfirm })
      .then(() => {
        dispatch(addAlert({ message: 'Mot de passe réinitialisé', type: AlertType.success }));
      })
      .catch(err => {
        const message = err?.message || 'La réinitialisation de mot passe a échoué';

        dispatch(addAlert({ message, type: AlertType.error }));
      })
      .finally(() => {
        resetForm();
      });
  };

  return (
    <Page maxWidth="sm" showHeader={false} className={classes.root}>
      <Box className={classes.content}>
        <Paper elevation={2} className={classes.paper}>
          <LockOutlinedIcon color="action" className={classes.icon} />

          <Typography variant="h5" gutterBottom>
            Réinitialiser mon mot de passe
          </Typography>

          <Container maxWidth="xs" className={classes.form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                name="password"
                type={showPassword ? 'text' : 'password'}
                label="Nouveau mot de passe"
                inputRef={register}
                error={!!errors?.password}
                helperText={errors?.password?.message}
                required
                autoComplete="password"
                variant="outlined"
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        tabIndex="-1"
                        edge="end"
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                name="passwordConfirm"
                type={showPasswordConfirm ? 'text' : 'password'}
                label="Confirmation de mot de passe"
                inputRef={register}
                error={!!errors?.passwordConfirm}
                helperText={errors?.passwordConfirm?.message}
                required
                autoComplete="off"
                variant="outlined"
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                        tabIndex="-1"
                        edge="end"
                      >
                        {showPasswordConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type="submit"
                title="Réinitialiser"
                fetching={formState.isSubmitting}
                className={classes.submitButton}
                fullWidth
              />
            </form>
          </Container>
        </Paper>
      </Box>

      <Box className={classes.logo}>
        <img src={logo} alt="Logo Gnoing" height={40} />
      </Box>
    </Page>
  );
};

export default withRouter(ResetPasswordPage);
