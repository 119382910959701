import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'containers/app';
import store from 'store';
import theme from 'theme';
import { setupAxiosInterceptors } from 'utils/api.utils';
import { Loader } from './components';
import './index.css';

const persistor = persistStore(store);

setupAxiosInterceptors(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.querySelector('#root'),
);
