import { LoginDTO, ResetPasswordDTO, User, VerifyEmailDTO } from '../interfaces';
import { api } from '../utils/api.utils';

const login = async (body: LoginDTO) => {
  const { data, headers } = await api.post<User>('/auth/login', body);

  const { jwt: token, refresh_token: refreshToken } = headers;

  return { token, refreshToken, user: data };
};

const logout = async (refreshToken: string) => {
  await api.post<void>('/auth/logout', { refreshToken });
};

const refresh = async (token: string) => {
  const { data } = await api.post<{ token: string; refreshToken: string }>('/auth/refresh', {
    refreshToken: token,
  });

  return data;
};

const resetPassword = async (data: ResetPasswordDTO) => {
  await api.post<void>('/auth/reset-password', data);
};

const verifyEmail = async (body: VerifyEmailDTO) => {
  const { data, headers } = await api.post<{ location: string | null }>(
    '/auth/verify-email',
    body,
    { withCredentials: true },
  );

  const { jwt: token, refresh_token: refreshToken } = headers;

  return { token, refreshToken, location: data.location };
};

export default { login, logout, refresh, resetPassword, verifyEmail };
