import React, { useState } from 'react';
import {
  Container,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { InferType, object, string } from 'yup';
import { Button } from './button';

type Props = {
  onSubmit: (values: FormData) => void;
  submitting: boolean;
  submitError?: string | null;
};

const LoginSchema = object().shape({
  email: string().email('Email invalide').required("L'email est requis"),
  password: string().required('Le mot de passe est requis'),
});

type FormData = InferType<typeof LoginSchema>;

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: spacing(4),
  },
  submitButton: {
    marginTop: spacing(3),
    marginBottom: spacing(2),
  },
}));

export const LoginForm: React.FC<Props> = ({ onSubmit, submitting, submitError }: Props) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: LoginSchema,
    reValidateMode: 'onSubmit',
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="xs" className={classes.root}>
      <Typography variant="h5" gutterBottom>
        Connexion
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="email"
          type="email"
          label="Email"
          inputRef={register}
          error={!!errors?.email}
          helperText={errors?.email?.message}
          required
          autoComplete="email"
          variant="outlined"
          fullWidth
          margin="normal"
        />

        <TextField
          name="password"
          type={showPassword ? 'text' : 'password'}
          label="Mot de passe"
          inputRef={register}
          error={!!errors?.password}
          helperText={errors?.password?.message}
          required
          autoComplete="password"
          variant="outlined"
          fullWidth
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  tabIndex="-1"
                  edge="end"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {submitError && (
          <Typography variant="body1" color="error" align="center">
            {submitError}
          </Typography>
        )}

        <Button
          type="submit"
          title="Se connecter"
          fetching={submitting}
          className={classes.submitButton}
          fullWidth
        />
      </form>
    </Container>
  );
};
