import { createMuiTheme } from '@material-ui/core';
import { common, grey, orange, teal } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: teal,
    secondary: orange,
    background: {
      default: grey[50],
      paper: common.white,
    },
  },
});

export default theme;
