import React, { ComponentProps, ReactNode } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Header } from './header';
import { Loader } from './loader';

type ContainerProps = ComponentProps<typeof Container>;

type Props = ContainerProps & {
  loading?: boolean;
  showHeader?: boolean;
  children: ReactNode;
};

const defaultProps = {
  loading: false,
  showHeader: true,
};

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    flex: 1,
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
  },
}));

export const Page: React.FC<Props> = ({ loading, showHeader, children, ...props }: Props) => {
  const classes = useStyles();
  const { className, ...containerProps } = props;

  const renderHeader = (): ReactNode => {
    if (!showHeader) {
      return null;
    }

    return <Header />;
  };

  const renderContent = (): ReactNode => {
    if (loading) {
      return (
        <Box flex={1} display="flex" justifyContent="center" alignItems="center">
          <Loader />
        </Box>
      );
    }

    return (
      <Container maxWidth="lg" className={clsx(classes.content, className)} {...containerProps}>
        {children}
      </Container>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};

Page.defaultProps = defaultProps;
