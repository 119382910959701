import { api } from '../utils/api.utils';

const authorize = async (): Promise<{ location: string }> => {
  const {
    data: { location },
  } = await api.get<{ location: string }>('/stripe/authorize', { withCredentials: true });

  return { location };
};

export default { authorize };
