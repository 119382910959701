import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  CheckRounded as CheckRoundedIcon,
  ReportProblemOutlined as ReportProblemOutlinedIcon,
} from '@material-ui/icons';
import { useAppDispatch } from 'hooks';
import { KnowingDetails } from 'interfaces';
import { Modal, showModal } from 'reducers/modal.reducer';

type Props = {
  knowingDetails: KnowingDetails;
};

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export const KnowingInfo: React.FC<Props> = ({ knowingDetails }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { activeCall, activeForum, curriculum, rank, stripeAccountId, tags, title } =
    knowingDetails;

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Mon profil sachant
        </Typography>

        <List dense subheader={<ListSubheader>Compte Stripe</ListSubheader>}>
          <ListItem>
            <ListItemText
              primary={<Typography variant="body2">Statut</Typography>}
              disableTypography
              secondary={
                stripeAccountId ? (
                  <Box display="flex" alignItems="center">
                    <CheckRoundedIcon color="primary" fontSize="small" />
                    &nbsp;
                    <Typography variant="body2" color="primary">
                      Connecté
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center">
                    <ReportProblemOutlinedIcon color="secondary" fontSize="small" />
                    &nbsp;
                    <Typography variant="body2" color="secondary">
                      Non connecté
                    </Typography>
                  </Box>
                )
              }
            />
            {!stripeAccountId && (
              <ListItemSecondaryAction>
                <Typography variant="body2">
                  <Link
                    href="#"
                    onClick={() => dispatch(showModal(Modal.unregisteredStripeAccount))}
                    color="secondary"
                  >
                    Créer mon compte Stripe
                  </Link>
                </Typography>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </List>
        {/* <Divider />
        <List dense subheader={<ListSubheader>Compétences</ListSubheader>}>
          <ListItem>
            <ListItemText primary="Titre" secondary={title} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Description" secondary={curriculum} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Rang" secondary={rank.description} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Mots-clés"
              secondary={
                tags.length > 0 ? (
                  <Grid container>
                    {tags.map(tag => (
                      <Grid item>
                        <Chip label={tag.label} size="small" className={classes.chip} />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  'n/a'
                )
              }
            />
          </ListItem>
        </List>
        <Divider />
        <List dense subheader={<ListSubheader>Notifications</ListSubheader>}>
          <ListItem>
            <ListItemIcon>
              <CallIcon />
            </ListItemIcon>
            <ListItemText primary="Appels" />
            <ListItemSecondaryAction>
              <Switch color="primary" checked={activeCall} disabled />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ForumIcon />
            </ListItemIcon>
            <ListItemText primary="Forum" />
            <ListItemSecondaryAction>
              <Switch color="primary" checked={activeForum} disabled />
            </ListItemSecondaryAction>
          </ListItem>
        </List> */}
      </CardContent>
    </Card>
  );
};
