import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useAppDispatch, useAppSelector, usePrevious } from 'hooks';
import { getMe } from 'reducers/auth.reducer';
import { hideModal, Modal, showModal } from 'reducers/modal.reducer';
import {
  KnowingInfo,
  Page,
  RegisteredStripeAccountModal,
  UnregisteredStripeAccountModal,
  UserInfo,
} from '../components';
import { getCurrentUser, getIsAuthenticating, getModalState } from '../selectors';

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentUser);
  const authenticating = useAppSelector(getIsAuthenticating);
  const modal = useAppSelector(getModalState);
  const previousUser = usePrevious(currentUser);

  useEffect(() => {
    dispatch(getMe());
  }, []);

  useEffect(() => {
    if (currentUser?.knowingDetails?.stripeAccountId === null) {
      dispatch(showModal(Modal.unregisteredStripeAccount));
    } else if (
      currentUser?.knowingDetails?.stripeAccountId &&
      previousUser?.knowingDetails?.stripeAccountId === null
    ) {
      dispatch(showModal(Modal.registeredStripeAccount));

      if (Modal.unregisteredStripeAccount) {
        dispatch(hideModal(Modal.unregisteredStripeAccount));
      }
    }
  }, [currentUser]);

  return (
    <Page maxWidth="md" loading={authenticating}>
      {currentUser && (
        <Grid container spacing={2} justify="center" alignItems="flex-start">
          <Grid item xs={12} sm={6} md={6} container spacing={2}>
            <Grid item xs={12}>
              <UserInfo user={currentUser} />
            </Grid>

            {/* {currentUser.address && (
                <Grid item xs={12}>
                  <UserAddressInfo address={currentUser.address} />
                </Grid>
              )} */}
          </Grid>

          {currentUser.knowingDetails && (
            <Grid item xs={12} sm={6} md={6}>
              <KnowingInfo knowingDetails={currentUser.knowingDetails} />
            </Grid>
          )}
        </Grid>
      )}

      <RegisteredStripeAccountModal
        open={modal.registeredStripeAccount}
        onClose={() => dispatch(hideModal(Modal.registeredStripeAccount))}
      />
      <UnregisteredStripeAccountModal
        open={modal.unregisteredStripeAccount}
        onClose={() => dispatch(hideModal(Modal.unregisteredStripeAccount))}
      />
    </Page>
  );
};

export default HomePage;
