import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Button } from '../button';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const RegisteredStripeAccountModal = ({ open, onClose }: Props) => {
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Compte Stripe créé</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Votre compte Stripe a bien été créé, vous êtes désormais visible dans les résultats de
          recherche Gnoing.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button title="Fermer" variant="text" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};
