import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum Modal {
  registeredStripeAccount = 'registeredStripeAccount',
  unregisteredStripeAccount = 'unregisteredStripeAccount',
}

type ModalState = { [key in Modal]: boolean };

const initialState: ModalState = {
  registeredStripeAccount: false,
  unregisteredStripeAccount: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<string>) => {
      return { ...state, [action.payload]: true };
    },
    hideModal: (state, action: PayloadAction<string>) => {
      return { ...state, [action.payload]: false };
    },
  },
});

export const { hideModal, showModal } = modalSlice.actions;
export default modalSlice.reducer;
